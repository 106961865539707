<template>
    <div class="industryMarket-content">
        <el-breadcrumb separator="/" class="genera-breadcrumb">
            <el-breadcrumb-item>采购管理</el-breadcrumb-item>
            <el-breadcrumb-item>数据分析</el-breadcrumb-item>
            <el-breadcrumb-item>行业分析</el-breadcrumb-item>
        </el-breadcrumb>
        <el-scrollbar class="scrollbar-content" :native="false">
            <div class="page-title">行业数据</div>
            <div class="industry-data flex">
                <div class="data-item">
                    <div class="data-title">
                        <span class="title">购买力</span><span class="subTitle">金额购买能力分布</span>
                    </div>
                    <div class="data-content" id="buyPower"></div>
                </div>
                <div class="data-item">
                    <div class="data-title">
                        <span class="title">品牌</span><span class="subTitle">客户品牌购买偏好</span>
                    </div>
                    <div class="data-content" id="brandPower"></div>
                </div>
            </div>
            <div class="page-title">行业大盘</div>
            <div class="industry-market">
                <div class="market-item">
                    <p class="market-title">成交金额指数</p>
                    <p class="market-number">{{transaction_amount_total}}</p>
                    <div class="market-content" id="turnoverIndex"></div>
                </div>
                <div class="market-item">
                    <p class="market-title">成交单量指数</p>
                    <p class="market-number">{{transaction_volume_total}}</p>
                    <div class="market-content" id="volumeIndex"></div>
                </div>
                <div class="market-item">
                    <p class="market-title">点击指数</p>
                    <p class="market-number">{{click_num_total}}</p>
                    <div class="market-content" id="clickIndex"></div>
                </div>
                <div class="market-item">
                    <p class="market-title">成交转化率</p>
                    <p class="market-number">{{transaction_conversion_total}}%</p>
                    <div class="market-content" id="conversionRateIndex"></div>
                </div>
                <div class="industry-market-data">
                    <div class="data-item">
                        <i class="iconfont">&#xe61e;</i>
                        <div class="right">
                            <p class="text">浏览量</p>
                            <p class="text">{{view_num_total}}</p>
                        </div>
                    </div>
                    <div class="data-item">
                        <i class="iconfont">&#xe619;</i>
                        <div class="right">
                            <p class="text">成交件数指数</p>
                            <p class="text">{{transaction_volume_total}}</p>
                        </div>
                    </div>
                    <div class="data-item">
                        <i class="iconfont">&#xe6dd;</i>
                        <div class="right">
                            <p class="text">成交客单价指数</p>
                            <p class="text">{{unit_price_total}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-title">趋势图</div>
            <div class="trend-wrapper">
                <div class="trend-category">
                    <div class="cate-left">
                        <p>引流漏斗</p>
                        <p>成交转化</p>
                    </div>
                    <div class="cate-right">
                        <el-checkbox-group v-model="checkList" :max="4">
                            <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="choose-number">
                    已选择:
                    <span class="number">
                    <span>{{checkList.length}}</span> / 4
                </span>
                </div>
                <div class="trend-content" id="trendECharts"></div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {tranPurchaseMarketData} from '@/utils/apis'
    export default {
        name: "IndustryMarket",
        data(){
            return{
                buyPower: null,
                brandPower: null,
                turnoverIndex: null,
                volumeIndex: null,
                clickIndex: null,
                conversionRateIndex: null,
                trendECharts: null,
                industryList: {},
                // dataActive: 'hangyeData',
                transaction_amount_total:'',//成交金额
                transaction_volume_total:'',//成交单量
                click_num_total:'',//点击率
                transaction_conversion_total:'',//转化率
                view_num_total:'',//浏览量
                unit_price_total:'',//客单价
                checkList: ['浏览量', '成交单量指数', '成交客单价', '成交转化率'],
                categoryList: ['浏览量', '点击指数', '成交单量指数', '成交客单价', '成交转化率', '成交件数指数', '成交金额指数'],
                allSeries: [],

            }
        },
        created() {
            // switch (Number(this.$route.query.type)) {
            //     case 1:
            //         this.dataActive = 'hangyeData';
            //         break;
            //     case 2:
            //         this.dataActive = 'brandList';
            //         break;
            // }
        },
        mounted() {
            this.getIndustryDetail()
            this.buyPower = this.$echarts.init(document.getElementById('buyPower'));
            this.brandPower = this.$echarts.init(document.getElementById('brandPower'));
            this.turnoverIndex = this.$echarts.init(document.getElementById('turnoverIndex'));
            this.volumeIndex = this.$echarts.init(document.getElementById('volumeIndex'));
            this.clickIndex = this.$echarts.init(document.getElementById('clickIndex'));
            this.conversionRateIndex = this.$echarts.init(document.getElementById('conversionRateIndex'));
            this.trendECharts = this.$echarts.init(document.getElementById('trendECharts'));
            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.buyPower.resize()
                    this.brandPower.resize()
                    this.turnoverIndex.resize()
                    this.volumeIndex.resize()
                    this.clickIndex.resize()
                    this.conversionRateIndex.resize()
                    this.trendECharts.resize()
                }, 100)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                // console.log('seriesVal', val)
                this.getTrendECharts()
            }
        },
        methods:{
            // 获取页面信息接口
            getIndustryDetail() {
                let param ={}
                if (this.$route.query.op_id) {
                    param.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                tranPurchaseMarketData(param).then((res) => {
                    console.log(res,'err')
                    this.industryList = JSON.parse(JSON.stringify(res.data))
                    this.transaction_amount_total = res.data.industryMarket.transaction_amount_total
                    this.transaction_volume_total = res.data.industryMarket.transaction_volume_total
                    this.click_num_total = res.data.industryMarket.click_num_total
                    this.transaction_amount_total = res.data.industryMarket.transaction_amount_total
                    this.transaction_conversion_total = res.data.industryMarket.transaction_conversion_total
                    this.view_num_total = res.data.industryMarket.view_num_total
                    this.unit_price_total = res.data.industryMarket.unit_price_total
                    // let transactionAmountArr = []
                    // for (const key in res.data.industryMarket.transaction_amount) {
                    //     let item = res.data.industryMarket.transaction_amount[key]
                    //     // console.log('key', key)
                    //     // console.log('item', item)
                    //     transactionAmountArr.push(item)
                    // }
                    // this.industryList.transaction_amount = transactionAmountArr
                    this.industryList.transaction_amount = this.formatArr(res.data.industryMarket.transaction_amount)
                    this.industryList.transaction_volume = this.formatArr(res.data.industryMarket.transaction_volume)
                    this.industryList.click_num = this.formatArr(res.data.industryMarket.click_num)
                    this.industryList.transaction_conversion = this.formatArr(res.data.industryMarket.transaction_conversion)
                    this.industryList.view_num = this.formatArr(res.data.industryMarket.view_num)
                    this.industryList.unit_price = this.formatArr(res.data.industryMarket.unit_price)
                    this.industryList.conversion = this.formatArr(res.data.industryMarket.conversion)
                    this.GetBuyPower()
                    this.GetBrandPower()
                    this.getTurnoverIndex()
                    this.getVolumeIndex()
                    this.getClickIndex()
                    this.getConversionRateIndex()
                    this.getTrendSeries()
                    // this.getTrendECharts()
                }).catch((err)  => {
                    console.log(err);
                })
            },
            //eCharts对象处理
            formatArr(data) {
                let arr = []
                for (const key in data) {
                    let item = data[key]
                    arr.push(item)
                }
                return arr
            },
            // 行业数据 eCharts
            setEChartsOption(item, xData, yData, title) {
                item.setOption({
                    xAxis: {
                        type: 'category',
                        data: xData,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: '#9A9A9A'
                            },
                            formatter: '{value} %'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'none',
                            label: {
                                backgroundColor: '#6a7985',
                            }
                        },
                        formatter: '{a}<br />{b} : {c}%',
                    },
                    grid: {
                        top: 20,
                        left: 50,
                        right: 0,
                        bottom: 25,
                    },
                    series: [{
                        name: title,
                        type: 'bar',
                        data: yData,
                        barWidth: 16,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#666',
                            formatter: '{c}%'
                        },
                        itemStyle: {
                            normal: {
                                barBorderRadius:[10, 10, 0, 0],
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: "#428AF5" // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#60C6FF" // 100% 处的颜色
                                    }
                                ], false)
                            },
                        },
                    }]
                });
            },
            // 行业数据——购买力
            GetBuyPower() {
                let xData = this.industryList.stuPurchasePower.x
                let yData = this.industryList.stuPurchasePower.y
                this.setEChartsOption(this.buyPower, xData, yData, '购买力')
            },
            // 行业数据——品牌
            GetBrandPower() {
                let xData = ['一线品牌', '二线品牌', '三线品牌']
                let yData = []
                for (let key in this.industryList.stuPurchaseLike) {
                    yData.push(this.industryList.stuPurchaseLike[key])
                }
                this.setEChartsOption(this.brandPower, xData, yData, '品牌')
            },
            // 行业大盘 eCharts
            setIndustryMarket(item, xData, yData, title) {
                item.setOption({
                    xAxis: {
                        type: 'category',
                        data: xData,
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                            // rotate: 40,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: false,
                            // textStyle: {
                            //     color: '#666'
                            // }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'none',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        // formatter: '&nbsp;{c}', //tootip前面显示一个圆圈，用字母O代替
                        backgroundColor: '#0084FF',
                        formatter: (params) => {
                            let res = null
                            if (params[0].seriesName === '成交转化率') {
                                res = params[0].seriesName + ' : ' + params[0].value + '%';
                            } else {
                                res = params[0].seriesName + ' : ' + params[0].value;
                            }
                            return res
                        }
                    },
                    grid: {
                        top: 20,
                        left: 10,
                        right: 20,
                        bottom: 25,
                    },
                    series: [{
                        name: title,
                        type: 'line',
                        data: yData,
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 8,
                        itemStyle: {
                            normal: {
                                color: "#0084FF",
                                borderColor: '#fff',
                                borderWidth: 2,
                            }
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(255, 255, 255, 0)'
                                },
                                {
                                    offset: 0.5,
                                    color: 'rgba(30, 136, 229, 0.3)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(30, 136, 229, 0.5)'
                                }
                            ], false)
                        },
                    }]
                });
            },
            // 行业大盘——成交金额指数
            getTurnoverIndex() {
                let xData = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
                let yData = this.industryList.transaction_amount
                this.setIndustryMarket(this.turnoverIndex, xData, yData, '成交金额指数')
            },
            // 行业大盘——成交单量指数
            getVolumeIndex() {
                let xData = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
                let yData =this.industryList.transaction_volume
                this.setIndustryMarket(this.volumeIndex, xData, yData, '成交单量指数')
            },
            // 行业大盘——点击指数
            getClickIndex() {
                let xData = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
                let yData = this.industryList.click_num
                this.setIndustryMarket(this.clickIndex, xData, yData, '点击指数')
            },
            // 行业大盘——成交转化率
            getConversionRateIndex() {
                let xData = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
                let yData = this.industryList.transaction_conversion
                this.setIndustryMarket(this.conversionRateIndex, xData, yData, '成交转化率')
            },
            // 趋势图
            getTrendECharts() {
                this.trendECharts.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: [  {
                        type: 'value',
                        name: '浏览量',
                        axisLabel: {           
                            show: false,        
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }, {
                        type: 'value',
                        name: '点击指数',
                        axisLabel: {           
                            show: false,        
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }, {
                        type: 'value',
                        name: '成交单量指数',
                        axisLabel: {           
                            show: false,        
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }, {
                        type: 'value',
                        name: '成交客单价',
                        axisLabel: {           
                            show: false,        
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }, {
                        type: 'value',
                        name: '成交转化率',
                        axisLabel: {           
                            show: false,        
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }, {
                        type: 'value',
                        name: '成交件数指数',
                        axisLabel: {           
                            show: false,        
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }, {
                        type: 'value',
                        name: '成交金额指数',
                        axisLabel: {           
                            show: false,        
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'none',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '成交转化率') {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + '%';
                                } else {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value;
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        // data: this.checkList,
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 10,
                        left: 10,
                        right: 20,
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
            getTrendSeries() {
                this.allSeries = [
                    {
                        name: '浏览量',
                        type: 'line',
                        data: this.industryList.view_num,
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: "#FFAC76",
                            }
                        },
                        yAxisIndex: 0,
                    },
                    {
                        name: '点击指数',
                        type: 'line',
                        data: this.industryList.click_num,
                        smooth: 'true',
                        itemStyle: {
                            normal: {
                                color: "#ffea00",
                            }
                        },
                        yAxisIndex: 1,
                    },
                    {
                        name: '成交单量指数',
                        type: 'line',
                        data: this.industryList.transaction_volume,
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: "#26C6DA",
                            }
                        },
                        yAxisIndex: 2,
                    },
                    {
                        name: '成交客单价',
                        type: 'line',
                        data: this.industryList.unit_price,
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: "#00d619",
                            }
                        },
                        yAxisIndex: 3,
                    },
                    {
                        name: '成交转化率',
                        type: 'line',
                        data: this.industryList.conversion,
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: "#4600d6",
                            }
                        },
                        yAxisIndex: 4,
                    },
                    {
                        name: '成交件数指数',
                        type: 'line',
                        data: this.industryList.transaction_volume,
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: "#0084FF",
                            }
                        },
                        yAxisIndex: 5,
                    },
                    {
                        name: '成交金额指数',
                        type: 'line',
                        data: this.industryList.transaction_amount,
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: "#E43C3E",
                            }
                        },
                        yAxisIndex: 6,
                    },
                ]
            }

        },
    }
</script>

<style scoped lang="scss">
    .industryMarket-content{
        .page-title {
            font-size: 20px;
            font-weight: 500;
            color: #333;
            margin: 20px 0;
            line-height: 1;
        }
        .industry-data {
            background: #fff;
            padding: 20px;
            .data-item {
                width: 1%;
                flex: 1;
                padding-right: 60px;
                .data-title {
                    line-height: 1;
                    .title {
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .subTitle {
                        font-size: 14px;
                        margin-left: 14px;
                    }
                }
                .data-content {
                    height: 300px;
                    margin-top: 30px;
                }
            }
        }
        .industry-market {
            display: flex;
            flex-wrap: wrap;
            margin-left: -40px;
            .market-item {
                width: calc(50% - 40px);
                background: #fff;
                margin: 0 0 20px 40px;
                padding: 20px;
                box-sizing: border-box;
                .market-title {
                    color: #666;
                    line-height: 1;
                }
                .market-number {
                    color: #666;
                    line-height: 1;
                    margin-top: 6px;
                    font-size: 16px;
                }
                .market-content {
                    margin-top: 20px;
                    height: 300px;
                }
            }
        }
        .industry-market-data {
            width: 100%;
            background: #fff;
            display: flex;
            .data-item {
                width: 33.333333%;
                padding: 30px 0;
                text-align: center;
                display: flex;
                justify-content: center;
                .iconfont {
                    font-size: 24px;
                    text-align: center;
                    line-height: 1;
                    color: #0084FF;
                }
                .right {
                    padding-left: 12px;
                    .text {
                        font-size: 18px;
                        line-height: 24px;
                        text-align: left;
                        margin-top: 12px;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .trend-wrapper {
            background: #fff;
            padding: 20px;
            .trend-category {
                display: flex;
                border: 1px solid #E5E5E5;
                .cate-left {
                    width: 120px;
                    background: #F8F8F8;
                    p {
                        line-height: 50px;
                        text-align: center;
                        font-size: 16px;
                        border-bottom: 1px solid #E5E5E5;
                        margin: 0;
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
                .cate-right {
                    width: 1%;
                    flex: 1;
                    .el-checkbox-group {
                        display: flex;
                        flex-wrap: wrap;
                        .el-checkbox {
                            margin-right: 0;
                            line-height: 50px;
                            padding-left: 48px;
                            width: 15%;
                            box-sizing: border-box;
                            &:nth-of-type(2) {
                                width: 85%;
                            }
                            &:nth-of-type(1), &:nth-of-type(2) {
                                border-bottom: 1px solid #E5E5E5;
                            }
                        }
                    }
                }
            }
            .choose-number {
                margin-top: 30px;
                font-size: 18px;
                .number {
                    margin-left: 20px;
                    span {
                        color: #0084FF;
                    }
                }
            }
            .trend-content {
                margin-top: 30px;
                height: 300px;
            }
        }

        .commodityTab {
            ::v-deep .el-tabs__header {
                margin: 0;
                .el-tabs__nav-wrap {
                    padding: 0 30px;
                }
                .el-tabs__active-bar {
                    background-color: #FD4446;
                }
                .el-tabs__item.is-active {
                    color: #FD4446;
                }
                .el-tabs__nav-wrap::after {
                    height: 1px;
                    background-color: #D1D2DB;
                }
            }
            ::v-deep .el-tabs__item {
                height: 80px;
                line-height: 80px;
                font-size: 20px;
                &:hover {
                    color: #FD4446;
                }
            }
        }
        .scrollbar-content{
            height: calc(100vh - 200px);
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #0084FF;
            border-color: #0084FF;
        }
        ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
            color: #0084FF;
        }
        ::v-deep .el-checkbox__inner:hover {
            border-color: #0084FF;
        }
    }

</style>